<template>
  <div class="profileTrackerModal__modal">
    <base-heading
        type="2"
        modifiers="center"
        class="profileTrackerModal__title"
    >
      {{ $t('profileTrackerModal.title') }}
    </base-heading>

    <div class="profileTrackerModal">
      <div class="profileTrackerModal__body">
        <div class="profileTrackerModal__bodyBlock">
          <div
              class="profileTrackerModal__bodyTitle"
              v-html-safe="$t('profileTrackerModal.body.block_1.title')"
          />
          <div
              class="profileTrackerModal__bodyContent"
              v-html-safe="$t('profileTrackerModal.body.block_1.content')"
          />
        </div>

        <div class="profileTrackerModal__bodyBlock">
          <div
              class="profileTrackerModal__bodyTitle"
              v-html-safe="$t('profileTrackerModal.body.block_2.title')"
          />
          <div
              class="profileTrackerModal__bodyContent"
              v-html-safe="$t('profileTrackerModal.body.block_2.content')"
          />
        </div>

        <div class="profileTrackerModal__bodyBlock">
          <div
              class="profileTrackerModal__bodyTitle"
              v-html-safe="$t('profileTrackerModal.body.block_3.title')"
          />
          <div
              class="profileTrackerModal__bodyContent"
              v-html-safe="$t('profileTrackerModal.body.block_3.content')"
          />
        </div>

        <div class="profileTrackerModal__bodyBlock">
          <div
              class="profileTrackerModal__bodyTitle"
              v-html-safe="$t('profileTrackerModal.body.block_4.title')"
          />
          <div
              class="profileTrackerModal__bodyContent"
              v-html-safe="$t('profileTrackerModal.body.block_4.content')"
          />
        </div>
      </div>
    </div>

    <div
        class="profileTrackerModal__buttons"
    >
      <base-button
          modifiers="secondary"
          class="profileTrackerModal__button"
          v-on:click="$emit('close')"
      >
        {{ $t('champModalStepConnect.closeButtonLabel') }}
      </base-button>

      <base-button
          v-if="member.integrations.champ"
          v-bind:show-spinner="!redirectUrl"
          modifiers="primary"
          class="profileTrackerModal__button"
          v-on:click.prevent="onContinueOverviewClick"
      >
        {{ $t('profileTrackerModal.body.linkChamp') }}
      </base-button>

      <base-button
          v-if="!member.integrations.champ"
          modifiers="primary"
          class="profileTrackerModal__button"
          v-on:click="onStartTrackerClick"
      >
        {{ $t('profileTrackerModal.body.linkKoppelen') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import {defineAsyncComponent, markRaw} from 'vue';
import {mapActions, mapState} from 'vuex';
import BaseButton from '@/components/BaseButton';
import {ModalBus} from '@/eventBus';
import BaseHeading from '@/components/BaseHeading';
import BaseAlert from "@/components/BaseAlert.vue";

const ChampModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/profile/ChampModal' /* webpackChunkName: "champModal" */),
}));

export default {
  components: {
    BaseAlert,
    BaseHeading,
    BaseButton,
  },

  computed: {
    ...mapState('member', ['member']),
  },

  data() {
    return {
      redirectUrl: null,
    };
  },

  async mounted() {
    try {
      const response = await this.champConnect();

      this.redirectUrl = response.redirect_url;
    } catch (error) {
    }
  },

  methods: {
    ...mapActions('member', [
      'champConnect',
    ]),

    onStartTrackerClick() {
      ModalBus.emit('open', {
        component: ChampModal,
      });
    },

    onContinueOverviewClick() {
      this.$emit('close');
      window.open(this.redirectUrl, '_blank');
    }
  },

};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileTrackerModal__buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 4rem auto 1rem auto;
  width: 100%;

  .profileTrackerModal__button {
    display: inline-block;
    margin: 0;
  }
}

.profileTrackerModal {
  margin: rem(32px) 0 0;

  .profileTrackerModal__body {
    .profileTrackerModal__bodyBlock {
      .profileTrackerModal__bodyTitle {
        @include heading-4;
      }

      .profileTrackerModal__bodyContent {
        @include paragraph;
        margin: 0 0 rem(32px) 0;
      }
    }
  }

}

</style>
